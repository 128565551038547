/* SP DRAWER MENU
____________________________________________________ */
.drawer-nav {z-index: 80!important;}
.drawer-btn {z-index: 70!important;}
.drawer-wrap {z-index: 60!important;}
#drawer { display: none; }

.drawer-nav {
  display: flex;
}
.drawer-btn {
  cursor: pointer;
  display: inline-block;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  transform: rotate3d(0,0,0,0deg);
  transition: all .1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  background: rgba(var(--color01),1);
}
.drawer-btn::after {
  content: '\0030e1\0030cb\0030e5\0030fc';
  font-size: 9px;
  color: rgba(var(--color08),1);
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  left: 0;
  right: 0;
  bottom: 5px;
  margin: 0 auto;
}
.drawer-btn span,
.drawer-btn span::before,
.drawer-btn span::after {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 1px;
  background: rgba(var(--color08),1);
}
.drawer-btn span {
  width: 40%;
  top: 36%;
}
.drawer-btn span::before {
  width: 100%;
  content: '';
  top: -6px;
  transform: rotate3d(0,0,0,0deg);
  transition: top .2s ease-in .2s, transform .2s ease-in;
}
.drawer-btn span::after {
  width: 100%;
  content: '';
  top: 6px;
  transform: rotate(0deg);
  transition: top .2s ease-in .2s, transform .2s ease-in;
}

#drawer:checked ~ .drawer-btn {
  right: calc( (20vw / 2 ) - (1.75rem / 2) );
  transform: rotate3d(0,0,1,0deg);
  transition: all .3s ease .1s;
}
#drawer:checked ~ .drawer-btn span {
  background: transparent !important;
  transition: background .1s ease;
  box-shadow: none;
}
#drawer:checked ~ .drawer-btn span::before,
#drawer:checked ~ .drawer-btn span::after {
  width: 100%;
  top: 0;
  bottom: 0;
  box-shadow: none;
  background: rgba(var(--color08),1);
}
#drawer:checked ~ .drawer-btn span::before {
  transform: rotate3d(0,0,1,45deg);
  top: 0;
  transition: top .2s ease, transform .2s ease .2s;
}
#drawer:checked ~ .drawer-btn span::after {
  transform: rotate3d(0,0,1,-45deg);
  top: 0;
  transition: top .2s ease, transform .2s ease .2s;
}

.drawer-wrap {
  display: block;
  position: fixed;
  left: -100vw;
  width: 100vw;

  top: calc(var(--gh-H) - var(--gh-nv-H));
  height: calc( 100vh - var(--gh-H) + var(--gh-nv-H) );
  padding-bottom: calc( var(--gh-H) - var(--gh-nv-H) );
  transform: translateX(0);
  transition: transform 0.5s ease;
  pointer-events: none;
  overflow-y: scroll;
  background: rgba(var(--color08),1);
}
#drawer:checked ~ .drawer-wrap {
  opacity: 1;
  transform: translateX(100vw);
  transition: transform 0.5s cubic-bezier(0.84, 0.06, 0.31, 0.88);
  pointer-events: auto;
  will-change: transform;
}

@media ( min-width: 641px) {
  .drawer-wrap {
    left: -80vw;
    width: 80vw;
  }
  #drawer:checked ~ .drawer-wrap {
    transform: translateX(80vw);
    transition: transform 1s ease;
  }
}
@media ( min-width: 834px ) {
  .drawer-nav {
    display: none;
  }
}

.overlay {
  /* display: none; */
  opacity: 0;
  visibility: hidden;
  backface-visibility: hidden;
  z-index: -1;
  background: rgba(0,0,0,0.6);
  position: fixed;
  width: 100vw;
  height: 100vh;
  right: 0;
  bottom: 0;
  left: 0;
  top: calc(var(--gh-H) - var(--gh-nv-H));
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: all 0.5s ease-out;
}
#drawer:checked ~ .drawer-wrap + .overlay {
  z-index: 9;
  opacity: 1;
  visibility: visible;
  transition: all 0.75s ease-out;
}

.article.single .drawer-wrap,
.article.single #drawer:checked ~ .drawer-wrap + .overlay {
  top: calc(var(--gh-H));
}

.drawer-inner {
  background: rgba(var(--color08),1);
  margin-bottom: 160px;
}

.drawer-inner .sec {
  padding: 0 0 1rem 0;
  margin: 0;
}
.drawer-inner .search-box {
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
  background: rgba(var(--color02),1);
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem 6vw;
}
.drawer-inner .search-box .search {
  appearance: none;
  border: 1px solid rgba(var(--color01),1);
  width: 100%;
  font-size: 16px;
  text-align: center;
  border-radius: 2em;
  padding: 0.5em;
}
.drawer-inner .dwr-slogan {
  background: rgba(var(--color04),1);
  color: rgba(var(--color08),1);
  font-size: min(4vw, 16px);
  font-weight: 700;
  line-height: 1.4;
  text-align: center;
  padding: 0.5em 1em;
  margin-bottom: 1rem;
}

.drawer-inner .sec .sec-headline {
  padding: 1.5rem 6vw 1rem 6vw;
  font-size: min(4vw, 14px);
}
.drawer-inner .dwr-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 0 1rem 0;
  width: calc( 100% - (6vw * 2) );
  margin: 0 auto;
}
.drawer-inner .dwr-links li {
  background: rgba(var(--color08),1);
  border: 1px solid rgba(var(--color01),1);
  border-radius: 5px;
  flex: 0 0 48%;
  margin: 0 1% 1.5% 1%;
}
.drawer-inner .dwr-links li a {
  color: rgba(var(--color01),1);
  font-size: min(4vw, 14px);
  font-weight: 700;
  padding: 1em 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drawer-inner .dwr-links li a::after {
  width: 20px;
  height: 1em;
  margin-left: 0.25em;
  content: '';
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m18.75 37.2-3.35-3.35 9.9-9.9-9.9-9.9 3.35-3.35L32 23.95Z" fill="%233471d6"/></svg>');
}

.drawer-inner .dwr-links li a[data-func]::after {
  transform: rotate(90deg);
}
.drawer-inner .dwr-links li a[data-func].showSubFunc {
  background: rgba(var(--color01),1);
  color: rgba(var(--color08),1);
  opacity: 1;
  transition: all 0.5s ease;
}
.drawer-inner .dwr-links li a[data-func].showSubFunc::after {
  transform: rotate(90deg) scale(-1, -1);
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m18.75 37.2-3.35-3.35 9.9-9.9-9.9-9.9 3.35-3.35L32 23.95Z" fill="%23fff"/></svg>');
}

.drawer-inner .subfunc {
  display: none;
  position: static;
  transform: none;
  margin: -7% auto 0 auto;
  width: calc( 100% - (6vw * 2) - 2% );
}
.drawer-inner .subfunc.showSubFunc {
  display: block;
}
.drawer-inner .subfunc > ul li a {
  white-space: normal;
}

.drawer-inner .dwr-links.btn-wrap li {
  border: none;
}
.drawer-inner .dwr-links.btn-wrap li a.btn {
  border: none;
  width: 100%;
  margin: 0;
  font-weight: 500;
  font-size: min(4vw, 18px);
}
.drawer-inner .dwr-links.btn-wrap li:only-child {
  flex-grow: 1;
  margin: 0 0 1.5% 0;
}
.drawer-inner .dwr-links.btn-wrap li a::after {
  display: none;
}
.drawer-inner .dwr-links.area-links {
  width: 100%;
  justify-content: space-between;
}
.drawer-inner .dwr-links.area-links li {
  border-radius: 0;
  width: calc( 100% / 2 );
  background: rgba(var(--color02),1);
  border: 1px solid rgba(var(--color08),1);
  flex: none;
  margin: 0;
}
.drawer-inner .dwr-links.area-links li a {
  justify-content: space-between;
  padding: 1em 2vw 1em 6vw;
}





/* GLOBAL NAV
____________________________________________________ */
.global-nav {
  width: 100%;
  padding: 0;
  align-items: center;
  background: rgba(var(--color08),1);
}
.global-nav ul {
  width: 100%;
  height: 57px;
  min-height: 57px;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-start;
}
.global-nav ul li {
  padding: 0;
}
.global-nav ul li .txt {
  color: rgba(var(--color00),0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(5vw, 13px);
  font-weight: 700;
  width: 100%;
  padding: 1.5rem 1rem 1.25rem 1rem;
  position: relative;
  transition: all .5s ease;
  cursor: pointer;
}
.global-nav ul li.current .txt {
  color: rgba(var(--color01),1);
}
.global-nav ul li.is-subnav .txt::after {
  content: '';
  width: min(3vw, 1em);
  height: min(3vw, 1em);
  margin-right: -4px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m24 30.75-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8Z"/></svg>');
}
.global-nav ul li.current .txt::before,
.global-nav ul li.showSub .txt::before {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 100%);
  background: rgba(var(--color01),1);
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  animation: gnav 0.25s cubic-bezier(0.2, 1, 0.2, 1) 0.5s 1 normal forwards;
}

/* while article rich_B */
.article.single .global-nav {
  display: none;
}
@media ( min-width: 641px ) {
  .global-nav {
    margin: 0 auto;
    padding: 0;
  }
}
@media ( min-width: 834px ) {
  .article.single .global-nav {
    margin: 0;
    border-bottom: none;
    display: flex;
    display: block;
  }
}
@media ( min-width: 1281px ) {
  .global-nav ul li:hover .txt::before,
  .global-nav ul li.is-subnav:hover .txt::before {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 100%);
    background: rgba(var(--color01),1);
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    animation: gnav 0.25s cubic-bezier(0.2, 1, 0.2, 1) 0.5s 1 normal forwards;
  }
}
@media ( max-width: 1660px ) {
  .article.single .global-nav {
    padding-left: 200px;
  }
}
@keyframes gnav {
  100% {
    width: 100%;
  }
}


/* while member page */
.member .global-nav {
  display: none !important;
}

.global-nav .splide-wrapper {
  position: relative;
}
.global-nav .splide__arrow {
  position: absolute;
  height: min(5vw,21px);
  cursor: pointer;
  line-height: 1;
  padding: 0;
  background: none;
  outline: none;
  border: none;
}
.global-nav .splide__arrow[disabled] {
  display: none;
}
.global-nav .splide__arrow > svg {
  display: none;
}
.global-nav .splide__arrow--prev,
.global-nav .splide__arrow--next {
  position: absolute;
  z-index: 10;
  bottom: 0;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--color02),0.75) !important;
}
.global-nav .splide__arrow--prev {
  left: 0;
}
.global-nav .splide__arrow--next {
  right: 0;
}
.global-nav .splide__arrow::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 65%;
}
.global-nav .splide__arrow--prev::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M28.05 37.2 14.8 23.95 28.05 10.7l3.35 3.35-9.9 9.9 9.9 9.9Z" fill="%23555"/></svg>');
}
.global-nav .splide__arrow--next::before {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m18.75 37.2-3.35-3.35 9.9-9.9-9.9-9.9 3.35-3.35L32 23.95Z" fill="%23555"/></svg>');
}
@media ( min-width: 1281px ) {
  .global-nav .splide__slide {
    position: static;
  }
  .global-nav .splide__list {
    padding: 0 calc((100% - 1280px) / 2) !important;
  }
}


/* CATEGORIES NAV
____________________________________________________ */
.subnav {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1000;
}
.subnav.showSub {
  opacity: 1;
  visibility: visible;
  top: calc( var(--gh-H) - 1px );
  overflow: auto;
  background: rgba(var(--color08),0.75);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 6px 26px 0 rgba(var(--color00),0.1);
  will-change: backdrop-filter, background, opacity;
  z-index: 10;
}

.subnav ul {
  width: 100%;
  max-width: var(--base-width);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1.5rem 0.5rem 1.5rem 0.5rem;
}
.subnav ul.hasTexts {
  border-top: 1px solid rgba(var(--color00),0.125);
}
.subnav ul.hasThumbs {
  border-top: 1px solid rgba(var(--color01),1);
}
.subnav ul.hasThumbs li {
  width: calc( 100% / 3 );
  flex: 0 1 auto;
  padding: 0.5rem;
}
.subnav ul.hasTexts li {
  width: calc( 100% / 2 );
  flex: 0 1 auto;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}
.subnav ul.hasTexts li::before {
  content: '';
  width: min(3vw, 14px);
  height: min(3vw, 14px);
  margin-right: 1em;
  border-radius: 50%;
  background-color: #FBE8EB;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m18.75 37.2-3.35-3.35 9.9-9.9-9.9-9.9 3.35-3.35L32 23.95Z" fill="%23CC5669"/></svg>');
}
.subnav ul li a {
  display: flex;
  flex-direction: column;
}
.subnav ul li a .catimg img {
  width: 100%;
  border-radius: 7px;
}
.subnav ul li a:hover .catimg img {
  opacity: 0.5;
  transition: opacity 0.75s ease;
}
.subnav ul li a .caption {
  text-align: left;
  font-size: min(3vw, 13px);
  font-weight: 700;
  padding: 0.5em 0;
  color: rgba(var(--color00),1);
}
.subnav ul.hasTexts li a .caption {
  padding: 0;
  font-size: calc(min(3vw, 13px) * 1.1);
}
@media ( min-width: 641px ) {
  .subnav.showSub {
    height: auto;
    padding-bottom: 0;
  }
  .subnav ul.hasThumbs {
    border-top: none;
  }
  .subnav ul.hasThumbs li {
    width: calc( 100% / 6 );
  }
  .subnav ul.hasThumbs li:nth-last-child(-n+5):first-child,
  .subnav ul.hasThumbs li:nth-last-child(-n+5):first-child ~ li {
    width: calc( 100% / 5 );
  }
  .subnav ul.hasTexts li {
    width: auto;
    flex: 0 0 auto;
    padding: 0.5rem;
    margin-right: 1.5em;
    display: flex;
    align-items: center;
  }
}
@media ( min-width: 1281px ) {
  .article.single .subnav.showSub {
    width: calc(100% + 200px);
    left: -200px;
    padding-left: 200px;
  }
}

@media (orientation: landscape) and (max-width: 834px ){
  .subnav.showSub {
    height: calc( 100vh - var(--gh-H) - var(--gh-nv-H));
    overflow: scroll;
    padding-bottom: calc( var(--gh-H) - var(--gh-nv-H) );
  }
}


.overlay.showSub {
  z-index: 9;
  opacity: 1;
  visibility: visible;
}

::-webkit-scrollbar {
  background: rgba(var(--color00),0.1);
  overflow: visible;
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(var(--color01), 0.6);
}
::-webkit-scrollbar-corner {
  background:transparent;
}
